import { useRoutes } from 'react-router-dom';
import * as jose from 'jose';
import EString from '../security/estring';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    let routes = [];

    const xtoken = localStorage.getItem('xtoken');
    if (xtoken) {
        const etoken = EString.deserialized(xtoken);
        const payload = jose.decodeJwt(etoken.str);
        if (payload.exp * 1000 >= Date.now()) {
            routes.push(MainRoutes);
        }
    }

    routes.push(AuthenticationRoutes);
    return useRoutes(routes);
}
