export const ADMIN_LEVELS = {
    NONE: 0,
    LOGIN: 1,
    CHECK_GATEWAYS_NAME: 2,
    CHECK_GATEWAYS: 4,
    EDIT_GATEWAYS: 8,
    ADD_GATEWAYS: 16,
    CHECK_FROM: 32,
    MERCHANT_RELATED: 64,
    MEMBER_RELATED: 128,
    get SUPER_ADMIN() {
        return Object.keys(ADMIN_LEVELS).reduce((sum, k) => sum | (k != 'SUPER_ADMIN' ? ADMIN_LEVELS[k] : 0), 0);
    }
};

export const uuid = () => {
    var d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};
