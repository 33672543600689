const RAW_SUPPORTED_GATEWAY_SERVICES = {
    credit_card_service: 'CREDIT_CARD (Stripe)',
    duolabao_service: 'DUOLABAO (哆啦宝)',
    srfu_service: 'SRFU (尚瑞付)',
    yffu_service: 'YFFU (揚飛付)',
    panzhipay_service: 'PANZHIPAY (攀陟科技)',
    alipay_service: 'ALIPAY (支付寶)'
    // personal_paycode_service: '個人收款碼Demo'
};

const RAW_SUPPORTED_GATEWAYS = {
    FALLBACK_GATEWAY: { id: '', name: '新支付渠道', icon: 'fas fa-question-circle' },
    CREDIT_CARD: { id: 'creditcard', name: '信用卡', icon: 'fa-regular fa-credit-card' },
    GOOGLE_PAY: { id: 'googlepay', name: 'Google Pay支付', icon: 'fa-brands fa-google-pay' },
    WX_QR: { id: 'wxqr', name: '微信QR', icon: 'fa-brands fa-weixin' },
    ALIPAY: { id: 'alipay', name: '支付寶', icon: 'fa-brands fa-alipay' },
    ALIPAY_QR: { id: 'alipayqr', name: '支付寶QR', icon: 'fa-brands fa-alipay' },
    WX: { id: 'wx', name: '微信', icon: 'fa-brands fa-weixin' },
    WX_QR: { id: 'wxqr', name: '微信QR Code', icon: 'fa-brands fa-weixin' },
    UNION_PAY: { id: 'unionpay', name: '銀聯/雲閃付', icon: 'fa-regular fa-money-bill-1' },
    UNION_PAY_QR: { id: 'unionpayqr', name: '雲閃付QR', icon: 'fa-solid fa-qrcode' },
    KIWI: { id: 'kiwi', name: 'KIWI支付', icon: 'fas fa-wallet' }
};

const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    // constant values
    SUPPORTED_GATEWAYS: RAW_SUPPORTED_GATEWAYS,
    SUPPORTED_GATEWAYS_OPTIONS: Object.keys(RAW_SUPPORTED_GATEWAYS).map((k) => {
        return { id: k, name: RAW_SUPPORTED_GATEWAYS[k].name };
    }),
    NEW_SUPPORTED_GATEWAYS: Object.keys(RAW_SUPPORTED_GATEWAYS).reduce((output, key) => {
        output[key.split('_').join('').toLowerCase()] = RAW_SUPPORTED_GATEWAYS[key];
        return output;
    }, {}),
    FALLBACK_GATEWAY: RAW_SUPPORTED_GATEWAYS.FALLBACK_GATEWAY,
    GatewayByID: (id) => {
        return config.NEW_SUPPORTED_GATEWAYS[id] ?? config.FALLBACK_GATEWAY;
    },
    EXCHANGE_RATES: {
        USD: {
            USD: 100,
            HKD: 700,
            CNY: 600
        },
        HKD: {
            USD: 14,
            HKD: 100,
            CNY: 85
        },
        CNY: {
            HKD: 116,
            USD: 16,
            CNY: 100
        }
    },
    SUPPORTED_GATEWAY_SERVICES: RAW_SUPPORTED_GATEWAY_SERVICES,
    SUPPORTED_GATEWAY_SERVICE_OPTIONS: Object.keys(RAW_SUPPORTED_GATEWAY_SERVICES).map((k) => {
        return { key: k.replace('_service', '').toUpperCase(), value: RAW_SUPPORTED_GATEWAY_SERVICES[k] };
    }),
    ORDER_STATUS: {
        ALL: null,
        PAID: 'paid',
        ERROR: 'error',
        CREATED: 'created'
    }
};

export default config;
