import * as jose from 'jose';
import EString from './estring';
import { ADMIN_LEVELS } from 'utils/constants';

export function tokenSave(token) {
    const etoken = new EString(token);
    localStorage.setItem('xtoken', etoken.serialized());
}

export function tokenDelete() {
    localStorage.removeItem('xtoken');
}

export function tokenGet() {
    return localStorage.getItem('xtoken');
}

export function tokenDecode(xtoken) {
    return EString.deserialized(xtoken).str;
}

export function accountSave(email) {
    const eaccount = new EString(email);
    localStorage.setItem('xaccount', eaccount.serialized());
}

export function accountGet() {
    const xaccount = localStorage.getItem('xaccount');
    return xaccount ? EString.deserialized(xaccount) : new EString();
}

export function accountLvGet() {
    const xtoken = tokenGet();
    if (xtoken) {
        const etoken = tokenDecode(xtoken);
        const tokenObj = jose.decodeJwt(etoken);
        return tokenObj.lv;
    }

    return 0;
}

export function accountLvNameGet() {
    const lv = accountLvGet();
    const names = Object.keys(ADMIN_LEVELS).filter((k) => ADMIN_LEVELS[k] <= lv);
    return names[names.length - 1];
}

export function accountMatchLv(lv) {
    const accLv = accountLvGet();
    return (accLv | lv) == accLv;
}

export function accountNameGet() {
    const xtoken = tokenGet();
    if (xtoken) {
        const etoken = tokenDecode(xtoken);
        const tokenObj = jose.decodeJwt(etoken);
        return tokenObj.name ?? accountGet().str.toUpperCase();
    }

    return 0;
}
