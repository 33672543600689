import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';
import EString from './security/estring';
import { tokenGet, tokenDecode } from './security/token';

// ==============================|| REACT DOM RENDER  ||============================== //
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL
});
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const xtoken = tokenGet();
    if (xtoken) {
        const etoken = tokenDecode(xtoken);
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                token: etoken
            }
        };
    }

    return { headers };
});
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </Provider>
    </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
