import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { accountMatchLv } from 'security/token';
import { ADMIN_LEVELS } from 'utils/constants';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Orders = Loadable(lazy(() => import('views/orders')));
const Gateways = Loadable(lazy(() => import('views/gateways')));
const Merchants = Loadable(lazy(() => import('views/merchants')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '*',
            element: <Navigate to="/orders" replace={true} />
        },
        {
            path: '/',
            element: <Navigate to="/orders" replace={true} />
        },
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'orders',
            element: <Orders />,
            children: [
                {
                    path: ':mode/:oid'
                }
            ]
        }
        // {
        //     path: 'dashboard',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <DashboardDefault />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-typography',
        //             element: <UtilsTypography />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-color',
        //             element: <UtilsColor />
        //         }
        //     ]
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-shadow',
        //             element: <UtilsShadow />
        //         }
        //     ]
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'tabler-icons',
        //             element: <UtilsTablerIcons />
        //         }
        //     ]
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons />
        //         }
        //     ]
        // },
        // {
        //     path: 'sample-page',
        //     element: <SamplePage />
        // }
    ]
};

const permissionRoutes = [
    {
        lv: ADMIN_LEVELS.CHECK_GATEWAYS,
        path: 'gateways',
        element: <Gateways />,
        children: [
            ...[
                (accountMatchLv(ADMIN_LEVELS.EDIT_GATEWAYS) || accountMatchLv(ADMIN_LEVELS.ADD_GATEWAYS)) && {
                    path: ':mode/:gid'
                }
            ],
            {
                path: ':mode'
            }
        ]
    },
    {
        lv: ADMIN_LEVELS.MERCHANT_RELATED,
        path: 'merchants',
        element: <Merchants />,
        children: [
            {
                path: ':mode/:mid'
            },
            {
                path: ':mode'
            }
        ]
    }
];

for (const route of permissionRoutes) {
    if (accountMatchLv(route.lv)) {
        MainRoutes.children.push(route);
    }
}

export default MainRoutes;
