// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <svg width="97px" height="22px" viewBox="0 0 447 101" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>A+ Pay</title>
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                fontFamily="Phosphate-Inline, Phosphate"
                fontSize="150"
                fontWeight="normal"
            >
                <text id="A+-Pay" fill="#000000">
                    <tspan x="3" y="101">
                        A+ Pay
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export default Logo;
