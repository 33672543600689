// assets
import { IconDashboard, IconDeviceDesktopAnalytics, IconApps, IconUsers, IconBuildingStore } from '@tabler/icons-react';
import { accountMatchLv } from 'security/token';
import { ADMIN_LEVELS } from 'utils/constants';

// constant
const icons = { IconDashboard, IconDeviceDesktopAnalytics, IconApps, IconUsers, IconBuildingStore };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const ITEMS_GATEWAY_ID = 'gateways';
const ITEMS_MERCHANT_ID = 'merchants';
const ITEMS_MEMBER_ID = 'members';

var items = [
    // {
    //     id: 'default',
    //     title: 'Dashboard',
    //     type: 'item',
    //     url: '/dashboard',
    //     icon: icons.IconDashboard,
    //     breadcrumbs: false
    // },
    {
        id: 'orders',
        title: 'Orders',
        type: 'item',
        url: '/orders',
        icon: icons.IconDeviceDesktopAnalytics,
        breadcrumbs: false
    }
];

const permissionItems = [
    {
        lv: ADMIN_LEVELS.CHECK_GATEWAYS,
        id: ITEMS_GATEWAY_ID,
        title: 'Gateways',
        type: 'item',
        url: '/gateways',
        icon: icons.IconApps,
        breadcrumbs: false
    },
    {
        lv: ADMIN_LEVELS.MERCHANT_RELATED,
        id: ITEMS_MERCHANT_ID,
        title: 'Merchants',
        type: 'item',
        url: '/merchants',
        icon: icons.IconBuildingStore,
        breadcrumbs: false
    }
];

const menu = {
    id: 'menu',
    title: 'Menu',
    type: 'group',
    get children() {
        for (const item of permissionItems) {
            if (accountMatchLv(item.lv) && !items.find((i) => i.id == item.id)) {
                items.push(item);
            }
        }

        return items;
    }
};

export default menu;
