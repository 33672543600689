import CryptoJS from 'crypto-js';
import { nanoid } from 'nanoid';

class EString {
    constructor(val = '') {
        this._update(val);
    }

    _encrypt(text, key, iv) {
        const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
            mode: CryptoJS.mode.CBC,
            iv: CryptoJS.enc.Utf8.parse(iv),
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    _decrypt(text, key, iv) {
        const decrypted = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
            mode: CryptoJS.mode.CBC,
            iv: CryptoJS.enc.Utf8.parse(iv),
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    _update(text) {
        for (let i = 0; this._max && i < this._max; i++) {
            delete this[`_${i}`];
        }
        this._max = 10 + Math.floor(Math.random() * 26);
        for (let i = 0; i < this._max; i++) {
            this[`_${i}`] = nanoid(16);
        }
        this._z = Math.floor(Math.random() * this._max);
        this._val = this._encrypt(text, this[`_${this._z}`], this[`_${(this._z + 1) % this._max}`]);
    }

    get str() {
        const tmp = this._decrypt(this._val, this[`_${this._z}`], this[`_${(this._z + 1) % this._max}`]);
        this._update(tmp);
        return tmp;
    }

    toString() {
        return this._val;
    }

    serialized() {
        return btoa(JSON.stringify(this));
    }

    static deserialized(data) {
        return Object.assign(new EString(), JSON.parse(atob(data)));
    }
}

export default EString;
